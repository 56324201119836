import * as React from 'react'
import { FieldBaseProps } from '..'
import { classNames } from './classnames'
import { FormErrorMessage } from './form-error-message'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

export type SelectProps = {
  label?: React.ReactNode
} & InvalidProps &
  FieldBaseProps &
  React.ComponentPropsWithoutRef<'select'>
type Ref = HTMLSelectElement

export const Select = React.forwardRef<Ref, SelectProps>(
  (
    {
      id,
      name,
      className,
      label,
      placeholder,
      children,
      isInvalid,
      invalidMessage,
      isHighlighted,
      required,
      ...restProps
    },
    ref
  ) => {
    return (
      <div className={className}>
        {label && (
          <FormLabel htmlFor={id || name} required={required}>
            {label}
          </FormLabel>
        )}
        <select
          ref={ref}
          id={id || name}
          name={name}
          className={classNames(
            isInvalid
              ? 'border-red-500 ring-1 ring-red-500 focus:border-blue-500 focus:ring-blue-500'
              : 'border-slate-200 focus:border-blue-500 focus:ring-blue-500',
            'block w-full overflow-hidden overflow-ellipsis rounded-md py-3 pl-3 pr-10 font-medium text-slate-600 disabled:opacity-50',
            isHighlighted ? 'text-blue-500' : 'text-slate-600',
            isHighlighted ? 'border-blue-500 bg-blue-50' : 'bg-white',
            className
          )}
          {...restProps}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {children}
        </select>
        {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
      </div>
    )
  }
)
