import * as React from 'react'
import { classNames } from './classnames'
import { SimplePasswordField } from './simple-password-field'
import type { InvalidProps } from './types'

export type PasswordFieldProps = {
  label?: React.ReactNode
  value: string
} & InvalidProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'type' | 'defaultValue'>
type Ref = HTMLInputElement

export const PasswordField = React.forwardRef<Ref, PasswordFieldProps>(
  ({ value, ...restProps }, ref) => {
    const hasEightCharactersOrMore = value?.length >= 8
    const hasOneUppercaseChar = /(.*[A-Z].*)/.test(value)
    const hasOneSpecialChar = /(.*\W.*)/.test(value)

    return (
      <SimplePasswordField
        ref={ref}
        value={value}
        extra={
          <div className="flex flex-wrap mt-2 -ml-5">
            <PasswordRequirement isFulfilled={hasEightCharactersOrMore}>
              &gt; 8 characters
            </PasswordRequirement>
            <PasswordRequirement isFulfilled={hasOneUppercaseChar}>
              1 uppercase char
            </PasswordRequirement>
            <PasswordRequirement isFulfilled={hasOneSpecialChar}>
              1 special char
            </PasswordRequirement>
          </div>
        }
        {...restProps}
      />
    )
  }
)

function PasswordRequirement({
  isFulfilled,
  children,
}: {
  isFulfilled: boolean
  children: React.ReactNode
}) {
  return (
    <div className="flex items-center mt-2 ml-5">
      <div
        className={classNames(
          'inline-flex items-center justify-center w-4 h-4 mr-1 border rounded-full border-gray flex-shrink-0',
          isFulfilled ? 'border-green bg-green' : 'border-gray'
        )}
      >
        <svg
          width="6"
          height="4"
          viewBox="0 0 6 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(isFulfilled ? 'text-white' : 'text-gray')}
        >
          <mask id="path-1-inside-1" fill="white">
            <path d="M1.14684 1.13805C0.877106 0.894511 0.448272 0.902493 0.189017 1.15588C-0.070239 1.40927 -0.0617417 1.81212 0.207996 2.05566L2.1648 3.82244C2.43809 4.06919 2.87373 4.05727 3.13127 3.796L5.81963 1.06873C6.07382 0.810857 6.05735 0.408231 5.78284 0.169442C5.50833 -0.0693467 5.07972 -0.0538747 4.82553 0.204L2.60608 2.45558L1.14684 1.13805Z" />
          </mask>
          <path
            d="M1.14684 1.13805L-0.193453 2.62251L-0.193453 2.62251L1.14684 1.13805ZM0.207996 2.05566L1.54829 0.571205V0.571205L0.207996 2.05566ZM2.1648 3.82244L0.824506 5.30689L2.1648 3.82244ZM3.13127 3.796L4.55561 5.20002L3.13127 3.796ZM5.81963 1.06873L4.39529 -0.335283V-0.335283L5.81963 1.06873ZM4.82553 0.204L3.40119 -1.20001V-1.20001L4.82553 0.204ZM2.60608 2.45558L1.26578 3.94004L2.6866 5.22287L4.03042 3.8596L2.60608 2.45558ZM2.48714 -0.346401C1.43668 -1.29485 -0.191346 -1.26897 -1.20892 -0.27442L1.58696 2.58618C1.08789 3.07396 0.31753 3.08387 -0.193453 2.62251L2.48714 -0.346401ZM-1.20892 -0.27442C-2.29688 0.78892 -2.2545 2.52689 -1.1323 3.54011L1.54829 0.571205C2.13102 1.09734 2.1564 2.02962 1.58696 2.58618L-1.20892 -0.27442ZM-1.1323 3.54011L0.824506 5.30689L3.5051 2.33798L1.54829 0.571205L-1.1323 3.54011ZM0.824506 5.30689C1.88759 6.26674 3.54206 6.22824 4.55561 5.20002L1.70693 2.39199C2.2054 1.8863 2.9886 1.87164 3.5051 2.33798L0.824506 5.30689ZM4.55561 5.20002L7.24397 2.47275L4.39529 -0.335283L1.70693 2.39199L4.55561 5.20002ZM7.24397 2.47275C8.31492 1.38629 8.23281 -0.350198 7.09546 -1.33954L4.47022 1.67843C3.88189 1.16666 3.83272 0.235428 4.39529 -0.335283L7.24397 2.47275ZM7.09546 -1.33954C6.03025 -2.26613 4.40264 -2.21596 3.40119 -1.20001L6.24987 1.60801C5.75681 2.10821 4.9864 2.12743 4.47022 1.67843L7.09546 -1.33954ZM3.40119 -1.20001L1.18174 1.05157L4.03042 3.8596L6.24987 1.60801L3.40119 -1.20001ZM3.94638 0.971127L2.48714 -0.346401L-0.193453 2.62251L1.26578 3.94004L3.94638 0.971127Z"
            fill="currentColor"
            mask="url(#path-1-inside-1)"
          />
        </svg>
      </div>
      <div
        className={classNames(
          'text-sm',
          isFulfilled ? 'text-green' : 'text-gray'
        )}
      >
        {children}
      </div>
    </div>
  )
}
