import { ChevronDownIcon } from '@upper/icons'
import { forwardRef, PropsWithChildren, ReactNode } from 'react'
import { Popover } from '../popover'
import { ClearButton } from './clear-button'
import { FilterDisplayProps } from './display'

type GroupDisplayProps = {
  id: string
  className?: string
  popOverContent: ReactNode
  defaultOpen?: boolean
  onInit?: (element: HTMLElement) => void
  onDestroy?: () => void
} & Omit<FilterDisplayProps, 'label'>

export const GroupDisplay = forwardRef<
  any,
  PropsWithChildren<GroupDisplayProps>
>(
  (
    {
      children,
      clearable,
      defaultOpen = false,
      onClear,
      className,
      popOverContent,
      ...props
    },
    ref
  ) => {
    return (
      <div ref={ref} {...props} className="relative p-[1px] h-[50px]">
        {clearable && <ClearButton onClick={onClear} />}
        <Popover
          content={popOverContent}
          defaultOpen={defaultOpen}
          modal
          contentProps={{ style: { zIndex: 100 }, align: 'center' }}
          triggerProps={{ asChild: true }}
        >
          <div className="bg-white border border-gray-light rounded px-3 py-3 h-[50px] flex gap-3 items-center">
            {children}
            <span className="text-black/5">|</span>
            <ChevronDownIcon width={10} className="text-gray" />
          </div>
        </Popover>
      </div>
    )
  }
)
