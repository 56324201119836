export const HTMLTagsRegex = /(<([^>]+)>)/gi;

export function initialsForStrings(
  ...strings: (string | undefined | null)[]
): string {
  return strings.map((s) => s?.substring(0, 1).toUpperCase()).join('')
}

export function formatNameInitials(
  name: {
    firstName?: string | null
    lastName?: string | null
  },
  order: 'FnLn' | 'LnFn' = 'FnLn'
): string {
  if (!name) return ''
  const names = [name.firstName, name.lastName]
  if (order === 'LnFn') names.reverse()
  return names
    .filter((n) => Boolean(n))
    .map((s) => s?.substring(0, 1).toUpperCase())
    .join('')
}

export function formatName(
  name:
    | {
      firstName?: string | null
      lastName?: string | null
    }
    | null
    | undefined,
  order: 'FnLn' | 'LnFn' = 'FnLn'
): string | undefined {
  if (!name) return undefined
  const names = [name?.firstName, name?.lastName]
  if (order === 'LnFn') names.reverse()
  return names.filter((n) => Boolean(n)).join(' ')
}

export function formatCurrency(
  value: number | null | undefined,
  maximumFractionDigits = 2
) {
  if (value === undefined || value === null) return null
  return Intl.NumberFormat('en-DE', {
    maximumFractionDigits,
    currency: 'EUR',
    style: 'currency',
  }).format(value)
}

export function extractUrl(str: string) {
  if (typeof str !== 'string') {
    return undefined
  }

  // eslint-disable-next-line no-useless-escape
  const urlPattern = /^(https?:\/\/[^\/]+)/i
  const match = str.match(urlPattern)

  if (match && match.length > 1) {
    return match[1]
  } else {
    return undefined
  }
}

export function isUUID(str: string) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

export function getHubspotURL(portalId: string, hubspotId: string): string {
  return `https://app.hubspot.com/contacts/${portalId}/company/${hubspotId}/`
}

export function getSlackChannelURL(channelId: string): string {
  return `slack://channel?team=TLRH1CBEJ&id=${channelId}`
}

export function getSlackUserURL(userId: string): string {
  return `slack://user?team=TLRH1CBEJ&id=${userId}`
}

export function isNotEmpty(value?: string | null): boolean {
  return value !== '' && value !== null && value !== undefined
}

export function sanitizeUUID(uuidString: string): string | null {
  // Regular expression to validate the UUID
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  // Test the UUID against the regular expression
  if (uuidRegex.test(uuidString)) {
    return uuidString;
  } else {
    return null;
  }
}
