import * as React from 'react'
import { classNames } from './classnames'
import { FormErrorMessage } from './form-error-message'
import { FormHelpText } from './form-help-text'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

export type TextareaProps = {
  label?: React.ReactNode
  helpText?: string | React.ReactNode
} & InvalidProps &
  React.ComponentPropsWithoutRef<'textarea'>
type Ref = HTMLTextAreaElement

export const Textarea = React.forwardRef<Ref, TextareaProps>(
  (
    {
      id,
      name,
      className,
      label,
      isInvalid,
      invalidMessage,
      helpText,
      ...restProps
    },
    ref
  ) => {
    return (
      <div>
        {label && (
          <FormLabel htmlFor={id || name} required={restProps.required}>
            {label}
          </FormLabel>
        )}
        <textarea
          ref={ref}
          id={id || name}
          name={name}
          className={classNames(
            isInvalid
              ? '!border-red-500 ring-1 ring-red-500 transition-all focus:border-red-500 focus:ring-red-500'
              : 'focus:border-blue-500 focus:ring-blue-500',
            'placeholder-muted block w-full rounded-md border-slate-300 px-4 py-3 transition-colors disabled:opacity-50',
            className
          )}
          {...restProps}
        />
        {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
        {helpText && <FormHelpText>{helpText}</FormHelpText>}
      </div>
    )
  }
)
