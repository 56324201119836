import { format, isValid, parse } from 'date-fns'
import { forwardRef, useCallback, useState } from 'react'
import { classNames } from '../lib/classnames'
import { DatePicker } from '../lib/date-picker'
import { FormLabel } from '../lib/form-label'
import { FieldBase, FieldBaseProps } from './field-base'
import * as Popover from '@radix-ui/react-popover'
import type { InvalidProps } from './types'
import { CalendarIcon } from 'lucide-react'

type DateFormat = 'iso' | 'filter'

const dateFormat: Record<DateFormat, string> = {
  iso: `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`,
  filter: 'dd/MM/yyyy',
}

type Props = {
  id?: string
  name?: string
  label?: React.ReactNode
  placeholder?: string
  /**
   * A date value (dd/mm/yyyy) - all dates before this date are not selectable
   */
  after?: string
  value?: string
  className?: string
  type?: DateFormat
  disablePast?: boolean
  required?: boolean
  containerStyle?: Partial<CSSStyleDeclaration>
  withHourPicker?: boolean
  disabled?: boolean
  onClosed?: () => void
  onChange?: (date: string | undefined) => void
} & InvalidProps &
  FieldBaseProps

export const DateField = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      name,
      label,
      placeholder,
      after,
      value = '',
      isInvalid,
      invalidMessage,
      className,
      type = 'iso',
      disablePast,
      required,
      containerStyle,
      withHourPicker,
      disabled,
      onClosed,
      onChange,
      ...props
    },
    ref
  ) => {
    const [wasOpen, setWasOpen] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const afterDate = parse(after || '', dateFormat[type], new Date(), {
      weekStartsOn: 1,
    })
    const date = parse(value, dateFormat[type], new Date(), {
      weekStartsOn: 1,
    })

    const hasValidDate = isValid(date) && !!value

    const defaultStartAddon = <CalendarIcon size={18} />

    const handleClose = useCallback(() => {
      if (!wasOpen) {
        setWasOpen(true)
        onClosed && onClosed()
      }
      setIsPopoverOpen(false)
    }, [wasOpen, onClosed])

    const handleDateSet = useCallback(
      (date: Date | undefined) => {
        const value =
          date && format(date, dateFormat[type], { weekStartsOn: 1 })
        handleClose()
        onChange && onChange(value)
      },
      [handleClose, onChange, type]
    )

    const displayDate =
      hasValidDate &&
      (withHourPicker
        ? Intl.DateTimeFormat('en-DE', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)
        : Intl.DateTimeFormat('en-DE', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          }).format(date))

    return (
      <div className={classNames(className)}>
        {label && (
          <FormLabel htmlFor={id || name} required={required}>
            {label}
          </FormLabel>
        )}
        <Popover.Root
          open={isPopoverOpen}
          onOpenChange={(o) => {
            setIsPopoverOpen(o)
          }}
          modal
        >
          <Popover.Trigger
            className={classNames(
              'w-full',
              disabled && 'pointer-events-none opacity-50'
            )}
          >
            <FieldBase
              ref={ref}
              isInvalid={isInvalid}
              isFocused={isPopoverOpen}
              showClear={hasValidDate}
              startAddon={props.startAddon || defaultStartAddon}
              onClear={() => handleDateSet(undefined)}
              {...props}
            >
              <span>
                {hasValidDate ? (
                  <span title={displayDate || ''}>{displayDate}</span>
                ) : (
                  <span>{placeholder}</span>
                )}
              </span>
            </FieldBase>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="rounded-md bg-white shadow-lg"
              align="start"
              sideOffset={8}
              style={{ zIndex: 100 }}
            >
              <div className="p-3">
                <DatePicker
                  after={afterDate}
                  value={date}
                  disablePast={disablePast}
                  onChange={handleDateSet}
                  withHourPicker={withHourPicker}
                />
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    )
  }
)

export type { Props as DateFieldProps }
