import { ArrowDownIcon, ArrowUpIcon, CheckIcon } from '@upper/icons'
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  getHours,
  getMinutes,
  getWeeksInMonth,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
  isValid,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { classNames } from '../lib/classnames'

const weekDays = 7

type DateValue = Date
type Props = {
  after?: DateValue
  disablePast?: boolean
  value?: DateValue
  withHourPicker?: boolean
  onChange?: (date?: Date) => void
}

// todo: make it global?
const locale = 'en-DE'
const currentDate = new Date()

export const DatePicker = ({
  after,
  disablePast,
  value,
  withHourPicker,
  onChange,
}: Props) => {
  const today = startOfDay(new Date())
  // const [date, setDate] = useState<DateValue | undefined>(value)
  const [calendarDate, setCalendarDate] = useState(
    value && isValid(value) ? value : currentDate
  )

  const [calendarDay, setCalendarDay] = useState(
    value && isValid(value) ? value : undefined
  )

  const [hours, setHours] = useState<number | undefined | ''>(
    calendarDay ? getHours(calendarDay) : undefined
  )
  const [minutes, setMinutes] = useState<number | undefined | ''>(
    calendarDay ? getMinutes(calendarDay) : undefined
  )

  const startOfMonthDate = startOfMonth(calendarDate)
  const startOfCalendarMonthDate = startOfISOWeek(startOfMonthDate)
  const monthWeeks = getWeeksInMonth(calendarDate, {
    locale: { code: locale },
    weekStartsOn: 1,
  })

  const days = useMemo(() => {
    const days = []
    for (let i = 0; i < weekDays * monthWeeks; i++) {
      days.push(i)
    }
    return days
  }, [monthWeeks])
  const daysInWeek = useMemo(() => {
    const days = []
    for (let i = 0; i < weekDays; i++) {
      days.push(i)
    }
    return days
  }, [])

  const daysInWeeks = days.reduce<number[][]>((previousValue, currentValue) => {
    const lastIndex = previousValue.length - 1
    if (
      previousValue[lastIndex] &&
      previousValue[lastIndex].length < weekDays
    ) {
      previousValue[lastIndex].push(currentValue)
    } else {
      previousValue.push([currentValue])
    }
    return previousValue
  }, [])

  const handleMonthChange = useCallback(
    (amount: number) => {
      setCalendarDate(addMonths(calendarDate, amount))
    },
    [calendarDate]
  )

  const handleDayChange = useCallback(
    (date: Date) => {
      setCalendarDay(date)
      if (!withHourPicker) onChange?.(date)
    },
    [onChange, withHourPicker]
  )

  const handleMonthReset = useCallback(() => {
    setCalendarDate(currentDate)
  }, [])

  const handleConfirmDate = useCallback(() => {
    if (calendarDay)
      onChange?.(
        addMinutes(addHours(startOfDay(calendarDay), hours || 0), minutes || 0)
      )
  }, [calendarDay, hours, minutes, onChange])

  return (
    <div className="font-mono text-sm">
      <header tabIndex={0}>
        <div className="flex items-center justify-between gap-2">
          <button
            className="text-lg text-gray-dark hover:text-gray-darkest p-2 rounded-md"
            onClick={() => handleMonthChange(-1)}
          >
            <ArrowUpIcon className="transform -rotate-90" />
          </button>
          <button onClick={handleMonthReset}>
            <h6 className="text-center font-medium font-sans text-base text-gray-dark p-2">
              {Intl.DateTimeFormat(locale, { month: 'long' }).format(
                startOfMonthDate
              )}{' '}
              <span className="text-gray">
                {Intl.DateTimeFormat(locale, { year: 'numeric' }).format(
                  startOfMonthDate
                )}
              </span>
            </h6>
          </button>
          <button
            className="text-lg text-gray-dark hover:text-gray-darkest p-2 rounded-md"
            onClick={() => handleMonthChange(1)}
          >
            <ArrowDownIcon className="transform -rotate-90" />
          </button>
        </div>
        <div className="grid grid-cols-7 gap-2 mt-2 text-gray-dark">
          {daysInWeek.map((wd) => (
            <div key={`h-wd-${wd}`} className="text-center">
              {Intl.DateTimeFormat(locale, { weekday: 'short' }).format(
                addDays(startOfCalendarMonthDate, wd)
              )}
            </div>
          ))}
        </div>
      </header>
      <div className="mt-3 space-y-1">
        {daysInWeeks.map((days, wi) => (
          <div key={`w-${wi}`} className="flex flex-wrap gap-1" tabIndex={-1}>
            {days.map((wd) => {
              const dayDate = addDays(startOfCalendarMonthDate, wd)
              const isDayBefore = after ? isBefore(dayDate, after) : false
              const isPastDate = disablePast ? isBefore(dayDate, today) : false
              const dayDateIsToday = isToday(dayDate)
              const dayDateIsDisabled = isDayBefore || isPastDate
              const dayDateIsSameMonth = isSameMonth(dayDate, startOfMonthDate)
              const dayDateIsSelected =
                calendarDay && isSameDay(dayDate, calendarDay)

              return (
                <button
                  key={`h-d-${wd}`}
                  tabIndex={0}
                  className={classNames(
                    'px-2 py-1 rounded',
                    dayDateIsToday && 'bg-gray-light',
                    dayDateIsSelected && 'bg-blue hover:bg-blue-dark',
                    dayDateIsSameMonth && 'hover:bg-gray-lightest',
                    dayDateIsSelected
                      ? 'text-white'
                      : dayDateIsDisabled || !dayDateIsSameMonth
                      ? 'text-gray'
                      : 'text-gray-darkest'
                  )}
                  onClick={() => handleDayChange?.(dayDate)}
                >
                  {startOfCalendarMonthDate &&
                    addDays(startOfCalendarMonthDate, wd) &&
                    Intl.DateTimeFormat(locale, { day: '2-digit' }).format(
                      addDays(startOfCalendarMonthDate, wd)
                    )}
                </button>
              )
            })}
          </div>
        ))}
      </div>
      {withHourPicker && (
        <div className="border-t border-gray-darkest/5 mt-3 pt-3 grid grid-cols-2 gap-3">
          {/* <p className='text-xs text-gray'>Select hour</p> */}
          <input
            type={'number'}
            min={0}
            max={24}
            maxLength={2}
            className="border border-gray-light rounded text-xs px-1 py-2 appearance-none"
            placeholder="0-24h"
            value={hours}
            onChange={(e) => {
              if (isNaN(parseInt(e.target.value))) setHours('')
              else setHours(Math.min(Math.max(parseInt(e.target.value), 0), 24))
            }}
            prefix={'hours'}
          />
          <input
            type={'number'}
            min={0}
            max={60}
            className="border border-gray-light rounded text-xs px-1 py-2 appearance-none"
            placeholder="0-60m"
            value={minutes}
            onChange={(e) => {
              if (isNaN(parseInt(e.target.value))) setMinutes('')
              else
                setMinutes(Math.min(Math.max(parseInt(e.target.value), 0), 60))
            }}
            prefix={'minutes'}
          />
        </div>
      )}
      {withHourPicker && (
        <button
          className="px-3 py-2 mt-3 flex gap-3 justify-between w-full rounded-md bg-gray-lightest text-blue hover:bg-gray-light"
          onClick={handleConfirmDate}
        >
          <CheckIcon />
          {calendarDay &&
            Intl.DateTimeFormat(locale, {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }).format(
              addMinutes(
                addHours(startOfDay(calendarDay), hours || 0),
                minutes || 0
              )
            )}
        </button>
      )}
    </div>
  )
}

export type { Props as DatePickerProps }
