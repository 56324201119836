import { path } from 'd3-path'
import update from 'immutability-helper'
import {
  forwardRef,
  Fragment, MutableRefObject, ReactNode,
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useMutationObserver } from 'rooks'
import { Tooltip } from '../tooltip'
import { ActionButton } from './action-button'
import { FilterDisplay } from './display'
import { FilterFieldProps } from './field'

type Props<T = any> = {
  name: string
  holderRef: MutableRefObject<HTMLElement>
  defaultValue?: any
  value?: any
  actionTooltip?: string
  onChange?: (value: any) => void
  onAdd?: () => any
  render: (
    value: any,
    {
      update,
      remove,
    }: {
      update: (index: number, value: any) => void
      remove: (index: number) => void
    }
  ) => ReactNode
} & FilterFieldProps

export const FilterCompound = forwardRef<HTMLElement, Props<any>>(
  (
    {
      name,
      holderRef,
      defaultValue,
      value: initialValue,
      actionTooltip,
      icon,
      label,
      tooltip,
      render,
      onAdd,
      onChange,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue ?? initialValue)
    const [rects, setRects] = useState<DOMRect[]>()

    const handleUpdateRects = useCallback(() => {
      setRects(
        Array.from(holderRef?.current?.children ?? [])
          ?.filter((e) => e.id.includes(name))
          ?.map((e) => e.getBoundingClientRect())
      )
    }, [holderRef, name])

    useMutationObserver(holderRef, handleUpdateRects, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
    })

    const handleAddGroup = useCallback(() => {
      if (!onAdd) return
      const newValue = [...value, onAdd?.()]
      // setValue(newValue)
      onChange?.(newValue)
    }, [onAdd, onChange, value])

    const handleUpdateGroup = useCallback(
      (groupIndex: number, updatedValue: any) => {
        const newValue = update(value, {
          [groupIndex]: { $merge: updatedValue },
        })
        // setValue(newValue)
        onChange?.(newValue)
      },
      [onChange, value]
    )

    const handleRemoveGroup = useCallback(
      (groupIndex: number) => {
        const newValue = update(value, { $splice: [[groupIndex, 1]] })
        // setValue(newValue)
        onChange?.(newValue)
      },
      [onChange, value]
    )

    useEffect(() => {
      const element = holderRef?.current
      if (!(element && element.addEventListener)) {
        return
      }
      handleUpdateRects()
      window.addEventListener('mousemove', handleUpdateRects)
      window.addEventListener('click', handleUpdateRects)
      window.addEventListener('resize', handleUpdateRects)
      return () => {
        window.removeEventListener('mousemove', handleUpdateRects)
        window.removeEventListener('click', handleUpdateRects)
        window.removeEventListener('resize', handleUpdateRects)
      }
    }, [holderRef, handleUpdateRects])

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue)
      }
    }, [defaultValue])

    useEffect(() => {
      if (initialValue) {
        setValue(initialValue)
      }
    }, [initialValue])

    const ConnectionSVG = useMemo(() => {
      const gap = 12
      return (
        <svg className="absolute top-0 left-0 z-0 pointer-events-none text-[#D0D4DF] overflow-visible">
          {rects?.map((rect, i) => {
            if (!rect) return <line />
            const prevRect = rects[i - 1]
            if (!prevRect) return null

            const startX = prevRect?.right ?? 0
            const startY = prevRect?.top + prevRect?.height / 2
            const c1X = prevRect?.right + gap / 2
            const c1Y = prevRect?.top + prevRect?.height / 2
            const c2X = c1X
            const c2Y = c1Y + (rect.top - prevRect.top) / 2
            const c3X = rect.left - gap / 2
            const c3Y = c2Y
            const c4X = c3X
            const c4Y = rect.top + rect.height / 2
            const endX = rect.left
            const endY = rect.top + rect.height / 2

            const p = path()
            p.moveTo(startX, startY)
            p.arcTo(c1X, c1Y, c2X, c2Y, 6)
            p.arcTo(c2X, c2Y, c3X, c3Y, 6)
            p.arcTo(c3X, c3Y, c4X, c4Y, 6)
            p.arcTo(c4X, c4Y, endX, endY, 6)
            p.lineTo(endX, endY)

            return (
              <path
                key={`polyline-${i}`}
                d={p.toString()}
                fill="none"
                stroke="#D0D4DF"
                strokeDasharray={`4`}
              />
            )
          }, [])}
        </svg>
      )
    }, [rects])

    return (
      <Fragment>
        {ConnectionSVG}
        <FilterDisplay
          id={`${name}-display`}
          clearable={value?.length > 0}
          hasSelection={value?.length > 0}
          onClear={() => onChange?.([])}
          tooltip={tooltip}
          label={label}
          icon={icon}
        />
        {render(value ?? defaultValue, {
          update: handleUpdateGroup,
          remove: handleRemoveGroup,
        })}
        <Tooltip
          content={<span>{actionTooltip}</span>}
          triggerProps={{ asChild: true }}
        >
          <ActionButton
            id={`${name}-action`}
            onClick={handleAddGroup}
            className="text-blue border-gray"
          />
        </Tooltip>
      </Fragment>
    )
  }
)
