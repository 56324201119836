'use client'

import * as RadixCollapsible from '@radix-ui/react-collapsible'
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { CollapsibleContext } from '@upper/providers'

const Collapsible = ({
  children,
  ...props
}: PropsWithChildren<RadixCollapsible.CollapsibleProps>) => {
  const context = useContext(CollapsibleContext)

  const [expanded, setExpanded] = useState(false)

  const handleValueChange = useCallback((value: boolean) => {
    setExpanded(value)
  }, [])

  useEffect(() => {
    if (!context) return
    setExpanded(context.expanded)
  }, [context])

  console.log(context)

  return (
    <CollapsibleContext.Provider value={{ expanded }}>
      <RadixCollapsible.Root
        {...props}
        open={expanded}
        onOpenChange={handleValueChange}
      >
        {children}
      </RadixCollapsible.Root>
    </CollapsibleContext.Provider>
  )
}

const CollapsibleTrigger = RadixCollapsible.CollapsibleTrigger

const CollapsibleContent = RadixCollapsible.CollapsibleContent

export { Collapsible, CollapsibleTrigger, CollapsibleContent }
