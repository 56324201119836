import { ValidatorProvider } from '@upper/providers'
import * as Icons from '@upper/sapphire/icons'
import { AlertDialogTrigger, Button, cn } from '@upper/sapphire/ui'
import { formatDate, isSameWeekDay } from '@upper/utils'
import {
  addDays,
  addWeeks,
  differenceInCalendarISOWeeks,
  endOfDay,
  format,
  formatISO,
  isWithinInterval,
  startOfDay,
  startOfWeek,
} from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
  HTMLAttributes,
  forwardRef,
  useContext,
  useMemo,
  useState,
} from 'react'
import * as z from 'zod'
import { classNames } from '../classnames'
import { ApproveConfirmationDialog } from './approve-confirmation-dialog'
import { JobCardContext } from './engagement-card'
import { EntriesWeekDay } from './entries-week-day'
import {
  TimeEntry,
  TimesheetAbsence,
  TimesheetDay,
  TimesheetDayStatus,
  TimesheetEngagementRevision,
  TimesheetExpense,
} from './types'

export type TimeEntryCreateType = 'TimeLog' | 'Absence' | 'Expense'
type Props = {
  isFreelancer?: boolean
  beginingDate?: Date
  endingDate?: Date
  days?: TimesheetDay[]
  absenceTypeOptions?: string[]
  expenseCategoryOptions?: string[]
  revisions?: TimesheetEngagementRevision[]
  onCreate?: (
    date: Date,
    type: TimeEntryCreateType,
    timesheetDayId?: string
  ) => void
  onDelete?: (id: string, type: TimeEntryCreateType) => void
  onTimeEntryChange?: (id: string, timeEntry: TimeEntry) => void
  onAbsenceChange?: (id: string, absence: TimesheetAbsence) => void
  onExpenseChange?: (id: string, expense: TimesheetExpense) => void
  onExpenseAssetUpload?: (id?: string | null) => void
  onExpenseAssetDelete?: (id?: string | null, assetId?: string | null) => void
  onApproveWeek?: (week: WeekRepresentation) => void
  onApproveDay?: (day: DayRepresentation) => void
  onUnapproveDay?: (day: DayRepresentation) => void
  onSubmit?: (weekDay: DayRepresentation) => void
}

const WEEK_DAYS = [0, 1, 2, 3, 4, 5, 6]

export type DayRepresentation = {
  date: Date
  day?: TimesheetDay
  hasActiveRevision: boolean
}

export type WeekRepresentation = {
  weekIndex: number
  days: DayRepresentation[]
}

type GridRepresentation = {
  headerDates: Date[]
  weeks: WeekRepresentation[]
}

export const Entries = ({
  isFreelancer = false,
  beginingDate,
  endingDate,
  days = [],
  absenceTypeOptions,
  expenseCategoryOptions,
  revisions,
  onAbsenceChange,
  onExpenseChange,
  onExpenseAssetUpload,
  onExpenseAssetDelete,
  onTimeEntryChange,
  onCreate,
  onDelete,
  onApproveWeek,
  onApproveDay,
  onUnapproveDay,
  onSubmit,
}: Props) => {
  const { folded, setFalse } = useContext(JobCardContext)
  const periodStart = beginingDate
  const periodEnd = endingDate

  const weeksDiff =
    periodStart && periodEnd
      ? differenceInCalendarISOWeeks(
          endOfDay(periodEnd),
          startOfDay(periodStart)
        ) + 1
      : 0

  const [weekdayHoverDate, setWeekdayHoverDate] = useState<Date | undefined>(
    undefined
  )

  const daysGrid: GridRepresentation = useMemo(() => {
    if (!periodStart || !periodEnd) return { headerDates: [], weeks: [] }
    const weeks = []
    for (let w = 0; w < weeksDiff; w++) {
      const entry: WeekRepresentation = {
        weekIndex: w,
        days: WEEK_DAYS.map((d) => {
          const date = addWeeks(
            addDays(startOfWeek(periodStart, { weekStartsOn: 1 }), d),
            w
          )

          const timesheetDay = days?.find(
            (td) =>
              formatISO(zonedTimeToUtc(new Date(td?.day ?? ''), 'GMT'), {
                representation: 'date',
              }) ===
              formatISO(zonedTimeToUtc(date, 'GMT'), { representation: 'date' })
          )

          const revision = revisions?.some((revision) =>
            isWithinRevisionPeriod(date, revision)
          )

          const hasActiveRevision = revision ?? false

          return {
            date,
            hasActiveRevision,
            day: timesheetDay,
          }
        }),
      }
      weeks.push(entry)
    }
    return {
      headerDates: WEEK_DAYS.map((d) =>
        addDays(startOfWeek(periodStart, { weekStartsOn: 1 }), d)
      ),
      weeks: weeks,
    }
  }, [periodStart, periodEnd, weeksDiff, days, revisions])

  return (
    <div className="text-gray-dark w-full snap-x snap-mandatory overflow-auto scroll-smooth">
      <ValidatorProvider
        schema={{
          absence: z.object({
            hours: z.number({ required_error: 'Required' }),
            notes: z.string().nullable().optional(),
          }),
          expense: z.object({
            ammount: z.number({ required_error: 'Required' }),
            notes: z
              .string({ required_error: 'Required' })
              .min(1, { message: 'Required' })
              .nullable(),
          }),
          timeEntry: z.object({
            hours: z.number({ required_error: 'Required' }),
            notes: z
              .string({ required_error: 'Required' })
              .min(1, { message: 'Required' })
              .nullable(),
          }),
        }}
      >
        <div
          className={classNames(
            'grid xl:min-w-fit',
            isFreelancer
              ? 'min-w-[1680px] grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr,1fr]'
              : 'min-w-[1840px] grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr,1fr,160px]',
            'border-gray-light border-b last:border-b-0'
          )}
        >
          {daysGrid?.headerDates?.map((headerDate) => (
            <WeekDayHeader
              key={`header-for-${formatISO(headerDate, {
                representation: 'date',
              })}`}
              date={headerDate}
              isHighlighted={isSameWeekDay(headerDate, weekdayHoverDate)}
            />
          ))}
          {!isFreelancer && (
            <div className="bg-gray-lightest border-gray-light border-l"></div>
          )}
        </div>
        {daysGrid?.weeks?.map((week) => {
          if (!periodStart || !periodEnd) return null

          const timesheetDaysAreSubmitted = week.days?.some(
            (td) => td.day?.status === TimesheetDayStatus.Submitted
          )

          const timesheetDaysAreApproved =
            (days?.length ?? 0) > 0 &&
            days?.every((td) => td.status === TimesheetDayStatus.Approved)

          return (
            <div
              className={classNames(
                'grid xl:min-w-fit',
                isFreelancer
                  ? 'grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr,1fr]'
                  : 'grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr,1fr,160px]',
                'border-gray-light border-b last:border-b-0',
                isFreelancer ? 'min-w-[1680px]' : 'min-w-[1840px]'
              )}
              key={week.weekIndex}
            >
              {week.days.map((weekDay) => {
                let isDayInRange = false
                try {
                  isDayInRange = isWithinInterval(weekDay?.date, {
                    start: periodStart,
                    end: periodEnd,
                  })
                } catch (e) {
                  console.log(e)
                }

                const isHighlighted = weekDay?.date
                  ? isSameWeekDay(weekDay.date, weekdayHoverDate)
                  : false

                if (!isDayInRange)
                  return (
                    <div
                      key={formatDate(weekDay?.date ?? new Date())}
                      className={cn(
                        'transition-colors',
                        'min-w-[200px] 2xl:min-w-fit',
                        isHighlighted && 'bg-gray-lightest'
                      )}
                    ></div>
                  )
                return (
                  weekDay && (
                    <EntriesWeekDay
                      key={`weekday-for-${formatISO(weekDay.date, {
                        representation: 'date',
                      })}`}
                      weekDay={weekDay}
                      isHighlighted={isHighlighted}
                      folded={folded}
                      onCreate={(date, type) =>
                        onCreate?.(date, type, weekDay.day?.id)
                      }
                      onDelete={onDelete}
                      onTimeEntryChange={onTimeEntryChange}
                      onAbsenceChange={onAbsenceChange}
                      onExpenseChange={onExpenseChange}
                      onHoverDateChange={setWeekdayHoverDate}
                      handleSubmit={() => onSubmit?.(weekDay)}
                      handleApprove={() => onApproveDay?.(weekDay)}
                      handleUnapprove={() => onUnapproveDay?.(weekDay)}
                      onExpand={setFalse}
                      absenceTypeOptions={absenceTypeOptions}
                      expenseCategoryOptions={expenseCategoryOptions}
                      isFreelancer={isFreelancer}
                      canCreateEntries={
                        weekDay.day &&
                        weekDay.day?.status === TimesheetDayStatus.Pending
                          ? true
                          : !weekDay.day
                      }
                      onExpenseAssetUpload={onExpenseAssetUpload}
                      onExpenseAssetDelete={onExpenseAssetDelete}
                    />
                  )
                )
              })}
              {!isFreelancer && (
                <div className="bg-gray-lightest border-gray-light relative border-l">
                  <header
                    className={classNames(
                      'px-2 py-3 font-mono',
                      getTotalHours(week.days.flatMap((d) => d?.day)) === 0 &&
                        'text-gray'
                    )}
                  >
                    {getTotalHours(week.days.flatMap((d) => d?.day)).toFixed(1)}
                    h
                  </header>
                  {timesheetDaysAreSubmitted && (
                    <div className={classNames('px-2 py-3 font-mono')}>
                      {onApproveWeek && (
                        <ApproveConfirmationDialog
                          onApprove={() => onApproveWeek(week)}
                        >
                          <AlertDialogTrigger asChild>
                            <ApproveButton className="font-sans" />
                          </AlertDialogTrigger>
                        </ApproveConfirmationDialog>
                      )}
                      {timesheetDaysAreApproved && (
                        <p
                          className={classNames(
                            folded
                              ? 'grid place-items-center'
                              : 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
                          )}
                        >
                          <Icons.CheckCircle
                            size={24}
                            strokeWidth={1.25}
                            className="text-[#00CA75]"
                          />
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </ValidatorProvider>
    </div>
  )
}

function getTotalHours(days: (TimesheetDay | undefined)[]) {
  return days.reduce((acc, e) => acc + (e?.totalHours || 0), 0)
}

type WeekDayHeaderProps = {
  date: Date
  isHighlighted?: boolean
}
const WeekDayHeader = ({ date, isHighlighted }: WeekDayHeaderProps) => {
  return (
    <div
      className={cn(
        'text-gray-dark mt-4 rounded-t-xl px-4 pb-2 pt-2 text-sm font-medium transition-colors',
        'min-w-[200px] 2xl:min-w-fit',
        isHighlighted && 'bg-gray-lightest'
      )}
    >
      {format(date, 'E')}
    </div>
  )
}

type ApproveButtonProps = HTMLAttributes<HTMLButtonElement>

const ApproveButton = forwardRef<HTMLButtonElement, ApproveButtonProps>(
  (props, ref) => {
    return (
      <Button ref={ref} variant={'secondary'} size={'xs'} {...props}>
        Approve All
      </Button>
    )
  }
)

function isWithinRevisionPeriod(
  date: Date,
  revision: TimesheetEngagementRevision
) {
  const currentDate = new Date()
  const appliesFrom = new Date(revision.appliesFrom || currentDate)
  const revisionEnd = new Date(revision.periodEnd || currentDate)

  try {
    return isWithinInterval(utcToZonedTime(endOfDay(date), 'GMT'), {
      start: utcToZonedTime(appliesFrom, 'GMT'),
      end: utcToZonedTime(revisionEnd, 'GMT'),
    })
  } catch (e) {
    console.log(e)

    return false
  }
}
